<template>
  <div>
    <SideNav />
    <TopNav />
  <div id="home">
    <div id="page" class="container">
       <div v-if="loading" id="loader"><div class="loader"></div></div>
      <div class="hello">
        <h1>{{ $t("WaitingBATOrders") }}</h1>
        <div class="row">
          <div class="col-lg-4 col-12">
            <div class="card">
              <div class="card-body row">
                <div class="col-3 text-center">
                  <i class="fas fa-cart-arrow-down colorpurple fa-lg"></i>
                </div>
                <div class="col-9">
                  <h4>{{ $t("Orders")}}</h4>
                  {{orders.length}} {{ $t("WaitingBATOrders")}}
                </div>
              </div>
            </div>
          </div>
        </div>

      <div class="card mb-4" id="bloctaborder">
        <div class="card-body">
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="orders"
            item-key="id"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :search="search"
            :footer-props="footerProps"
          >
          <template v-slot:top>
           <v-text-field
             v-model="search"
             label="Recherche" style="display:inline-block;width:76%;margin-left:2%;margin-right:2%;"
           ></v-text-field>
           <v-btn class="btn" @click="downloadCSVData" style="display:inline-block;width:16%;margin-left:2%;margin-right:2%;">
             {{$t("CSV")}}
           </v-btn>
         </template>
          <template v-slot:item.ref="{ item }">
            <a :class="{ 'batok': item.array_options.options_validclientbat }" :href="'/order/'+ item.id">{{item.ref}}</a>
          </template>

          <template v-slot:item.date_commande="{ item }">{{ item.date_commande | moment }}</template>
          <template v-slot:item.date_livraison="{ item }">{{ item.date_livraison | moment }}</template>
          <template v-slot:item.date_bat="{ item }">
            <span v-if="item.array_options.options_envoibat">{{ item.array_options.options_envoibat | momentText}}</span>
            <span v-else>{{$t("NotSend")}}</span>
          </template>

          <template v-slot:item.ofone="{ item }" >{{item.ofdone}} / {{item.of.TAssetOFLine.length}}</template>
          <template v-slot:item.statut="{ item }">
            <span v-if="item.statut == 0" class="badge badge-dark">{{$t("Draft")}}</span>
            <span v-if="item.statut == 1" class="badge" :class="item.array_options.options_validclientbat ? 'badge-info' : 'badge-warning'">
              <span v-if="!item.array_options.options_validclientbat">{{$t("WaitingBAT")}} <span v-if="item.array_options.options_envoibat">{{$t("ClientSide")}}</span></span>
              <span v-if="item.array_options.options_validclientbat">{{$t("BATOKlong")}}</span>
            </span>
            <span v-if="item.statut == 2" class="badge badge-primary">{{$t("InProduction")}}</span>
            <span v-if="item.statut == 3" class="badge badge-success">{{$t("End")}}</span>
          </template>

          <template v-slot:item.id="{ item }">
            <a :href="'/order/'+ item.id"><i class="far fa-eye"></i></a>
          </template>

        </v-data-table>
        </div>
      </div>
  </div>


    </div>
  </div>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import moment from 'moment'
import SideNav from "@/components/navbar.vue";
import TopNav from "@/components/topnav.vue";
export default {
  name: 'Home',
  components: {SideNav,TopNav},
  data(){
    return {
       loading: false,
      selected: [],
      orders : [],
      ofdone: 10,
      oftotal: 70,
      headers: [
        {
          text: 'N°',
          value: 'ref',
          sortable: true,
          align: 'left',
        },
        {
          text: this.$i18n.t("OrderCustomerRef"),
          value: 'ref_client',
          sortable: true,
          align: 'left',
        },
        {
          text: this.$i18n.t("OrderDate"),
          value: 'date_commande',
          sortable: true,
          align: 'left',
        },
        {
          text: this.$i18n.t("DeliveryDate"),
          value: 'date_livraison',
          sortable: true,
          align: 'left',
        },
        {
          text: this.$i18n.t("BATDate"),
          value: 'date_bat',
          sortable: true,
          align: 'left',
        },
        {
          text: this.$i18n.t("Customer"),
          value: 'client.nom',
          sortable: true,
          align: 'left',
        },
        {
          text: this.$i18n.t("OF"),
          value: 'ofone',
          sortable: true,
          width: '10%',
          align: 'left',
        },
        {
          text:this.$i18n.t("Status"),
          value: 'statut',
          sortable: true,
          align: 'left',
        },
        {
          text: '#',
          value: 'id',
          sortable: true,
          width: '10%',
          align: 'left',
        },
      ],
      sortBy: 'date_commande',
      sortDesc: true,
      search: '',
      footerProps: {
        'items-per-page-options': [25, 50, 100, 500],
      },
    }
  },
  mounted () {
    this.loading = true
    axios
      .get(
        "https://crm.bonbonprint.fr/api/index.php/orders/ordersListWithOfs?sortfield=t.date_creation&sortorder=ASC&limit=200&sqlfilters=(t.fk_statut:=:'1')",
        {
          headers: {
            'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9'
            //the token is a variable which holds the token
          }
        }
      )
      .then(response => {
        console.log(response);
        this.orders = response.data.olist
        this.orders.forEach((o) => {
            o.ofdone = o.of.TAssetOFLine.filter((obj) => obj.done == "true").length;
        });
        this.loading = false
      })
  },
  filters: {
    moment: function (date) {
      return (date) ? moment(date*1000).format('DD/MM/YYYY') : "N.C.";
    },
    momentText: function (date) {
      return (date) ? moment(date).format('DD/MM/YYYY') : "N.C.";
    }
  },
  methods:{
    downloadCSVData() {
        let csv = 'Ref commande;Ref client;Tiers;Entrepot de départ;Date de saisie; Date de livraison; Contact de Livraison; Adresse de livraison; Code postal de livraison; Ville livraison; Ref produit; Libéllé produit;Quantité;\n';
        this.orders.forEach((o) => {
              console.log(o);
              var  lineCsv = "";
              var  tempCsv = "";
          tempCsv += o.ref;
          tempCsv += ";"+o.ref_client;
          tempCsv += ";"+o.client.name;
          tempCsv += ";"+o.array_options.options_otcwarehouse;
          tempCsv += ";"+ moment(o.date_commande*1000).format('DD/MM/YYYY');
          tempCsv += ";"+ moment(o.date_livraison*1000).format('DD/MM/YYYY');
          if(o.contact_livraison.length > 0){
            tempCsv += ";"+ (o.contact_livraison.lastname) ? o.contact_livraison.lastname.replace(/(\r\n|\n|\r)/gm, "") : '';
            tempCsv += ";"+(o.contact_livraison.address) ? o.contact_livraison.address.replace(/(\r\n|\n|\r)/gm, "") : '';
            tempCsv += ";"+(o.contact_livraison.zip) ? o.contact_livraison.zip : '';
            tempCsv += ";"+(o.contact_livraison.town) ? o.contact_livraison.town : '';

          }
          else{
            tempCsv += ";"+o.client.name.replace(/(\r\n|\n|\r)/gm, "");
            tempCsv += ";"+o.client.address.replace(/(\r\n|\n|\r)/gm, "");
            tempCsv += ";"+o.client.zip;
            tempCsv += ";"+o.client.town;
          }


          o.of.TAssetOFLine.forEach((item) => {
            lineCsv = "";
            lineCsv += ";"+item.product.ref;
            lineCsv += ";"+item.product.label
            lineCsv += ";"+item.qty
            lineCsv += "\n";
            csv += tempCsv+lineCsv
          });

        });
        console.log(csv);
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        anchor.target = '_blank';
        anchor.download = 'nameYourFileHere.csv';
        anchor.click();
    }
  }
}
</script>

<style lang="scss" scoped>
#loader{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.8);
  z-index: 500000;
}
.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 250px;
height: 250px;
transform: translate(-50%,-50%);
position: absolute;
left: 0;
right: 0;
margin-left: auto;
margin-right: auto;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
