import { render, staticRenderFns } from "./sendToProduction.vue?vue&type=template&id=88fc67dc&scoped=true&lang=html&"
import script from "./sendToProduction.vue?vue&type=script&lang=js&"
export * from "./sendToProduction.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88fc67dc",
  null
  
)

export default component.exports