import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VModal from 'vue-js-modal'
import FileUpload from 'v-file-upload'
import Notifications from 'vue-notification'
import VueCookies from 'vue-cookies';
import i18n from './i18n'
import Vuex from "vuex";
import store from "./store";

Vue.config.productionTip = false
Vue.use(VModal)
Vue.use(FileUpload)
Vue.use(Notifications)
Vue.use(VueCookies);
Vue.use(Vuex);

new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
