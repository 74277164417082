<template >
  <tr>
    <td>
      <Impression @impressionend="handleprintend"  @impressiondone="handleprint" :line="item" :ofid="o.of.id" v-if="(o.of.status == 'OPEN' || o.of.status == 'VALID') && item.date_start_line && !item.date_end_line " />
      <StartImpression v-if="!item.date_start_line && !item.date_end_line "  @impressionend="handleprintend" @impressiondone="handleprint" :line="item" :orderRef="o.ref" :parentPrinters="parentPrinters"/>
      <!-- <EndImpression @impressionend="handleprintend" :line="item" v-if="(o.of.status == 'OPEN' || o.of.status == 'VALID') && item.date_start_line && !item.date_end_line && item.printablefile != ''" /> -->
    <td>
      <img :src="item.thumbnail" alt="" class="img-fluid" style="width:150px;margin:2px;">
      <img v-if="item.white_thumbnail" :src="item.white_thumbnail" alt="" class="img-fluid" style="width:150px;margin:2px;">
    </td>
    <td>{{o.ref}}</td>
    <td>{{o.client.name}}</td>
    <td>{{item.product.ref}}</td>
    <td>{{item.product.label}}</td>
    <td>{{item.qty_used}} / {{item.qty}}</td>
    <td>{{item.machine}}</td>
    <td><StatutOf :statut="o.of.status"/></td>
    <td class="actionscol">
        <a :href="'/order/'+ o.id"><i class="fas fa-eye"></i></a>
        <PrintOfPdf :ofid="o.of.id"/>
        <span v-if="(o.of.status != 'DRAFT') && item.date_start_line && item.date_end_line"><br/>{{$t("ProductionTime:")}} {{tempsProdctionCalc  | prettyPrintSecond }} </span>
    </td>

  </tr>

</template>

<script>
import moment from "moment"
import Impression from "@/components/buttons/impression.vue"
import StartImpression from "@/components/buttons/startimpression.vue"
// import EndImpression from "@/components/buttons/endimpression.vue"
import PrintOfPdf from "@/components/buttons/printOfSmall.vue"
import StatutOf from '@/components/buttons/statutOf.vue'
export default {
  name : "OfLine",
  components: {
    PrintOfPdf,
    Impression,
    StartImpression,
    // EndImpression,
    StatutOf
  },
  props : {
    o : Object,
    item : Object,
    parentPrinters: Object,
 },
 methods: {
   handleprint : function(r){
     console.log(r);
     var dack = this.item
     this.item = r.data
     this.item.orderline = dack.orderline
     this.$forceUpdate()
  },
  handleprintend : function(r){
    var dack = this.item
    this.item = r.data
    this.item.orderline = dack.orderline
    this.$forceUpdate()
    this.$emit("anItemIsEnded",this.item.id)
 }
},
filters: {
  prettyPrintSecond: function (duration) {
    return (duration) ? moment({}).startOf('day').seconds(duration).format("HH[H] mm[min.] ss[sec.]") : "N.C.";
  }
},
computed: {
  tempsProdctionCalc : function(){
    var tempsProduction = this.item.date_end_line - this.item.date_start_line;
    return tempsProduction
  }
}
}
</script>
