<template lang="html">
  <div class="">
    <button class="btn btn-success" type="button" name="button" @click="endOF">
      <i class="far fa-check-square"></i>
      {{$t("EndOf",  { of: ofid})}}</button>
  </div>

</template>

<script>
import axios from 'axios';

export default {
  name: "EndProductionOf",
  props : [
    "ofid"
  ],
  methods: {
    endOF : function(){
      if(confirm(this.$i18n.t('EndImpressionConfirm'))){
        console.log("Confirmed");
        axios.post(
          "https://crm.bonbonprint.fr/api/index.php/of/terminersimpleOF",
          {ofid:parseInt(this.ofid),lines:[]},
          {
            headers: {
              'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
            }
          }
        )
        .then(response => {
          console.log(response);
              this.$emit("closeOf",this.ofid);
        });
      }

    },

  },


}
</script>
