<template>

  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">

        <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
            <div class="sb-sidenav-menu">
                <div class="nav">
                  <div class="sb-sidenav-menu-heading">{{ $t("SalesAdministration")}}</div>
                  <a class="nav-link" href="/ADV">
                      <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                      {{$t("BATTracking")}}
                  </a>
                            <div class="sb-sidenav-menu-heading">{{ $t("Productions")}}</div>
                    <a class="nav-link" href="/">
                        <div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i></div>
                        {{ $t("WaitingBAT") }}
                    </a>
                    <a class="nav-link" href="/productions">
                        <div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i></div>
                        {{ $t("InProduction")}}
                    </a>
                    <a class="nav-link" href="/audepart">
                      <div class="sb-nav-link-icon"><i class="fas fa-tachometer-alt"></i></div>
                      {{ $t("End")}}
                    </a>
                    <div class="sb-sidenav-menu-heading">{{ $t("SUPERVISION")}}</div>
                    <a class="nav-link" href="/incident">
                        <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                        {{$t("IncidentsTracker")}}
                    </a>
                    <a class="nav-link" href="#">
                        <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                        {{ $t("Statistics")}}
                    </a>

                    <div class="wrap-collabsible">
                      <input id="collapsible" class="toggle" type="checkbox">
                      <label for="collapsible" class="lbl-toggle">Générateur</label>
                    <div class="collapsible-content">
                    <a class="nav-link" href="/generateurbbp/#!/persosucre">
                        <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                        Sucres
                    </a>
                    <a class="nav-link" href="/generateurbbp/#!/persosavon">
                        <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                        Savon
                    </a>
                    <a class="nav-link" href="generateurbbp/#!">
                        <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                        Bonbons recto
                    </a>
                    <a class="nav-link" href="/generateurbbp/#!/persocube">
                        <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                        Cube
                    </a>
                    <a class="nav-link" href="/generateurbbp/#!/persocoffret">
                        <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                        Petit coffret
                    </a>
                    <a class="nav-link" href="/generateurbbp/#!/persoplaque1520">
                        <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                        Plaque 15x20
                    </a>
                    <a class="nav-link" href="/generateurbbp/#!/persoplaque2030">
                        <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                        Plaque 20x30
                    </a>
                    <a class="nav-link" href="/generateurbbp/#!/persoplaque3040">
                        <div class="sb-nav-link-icon"><i class="fas fa-columns"></i></div>
                        Plaque 30x40
                    </a>
                    </div>
                  </div>
                </div>
            </div>
            <div class="sb-sidenav-footer">
                <div class="small">V 1.0.0</div>
            </div>
        </nav>
    </div>
    </div>
</template>
<script>
export default {
  name : "SideNav",
}
</script>
