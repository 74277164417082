<template lang="html">
  <div class="btnBlock">
    <button class="btn btn-success" type="button" name="button" @click="show">
      <i class="fas fa-truck"></i>
      <span  v-if="this.mode != 'libre'"> Envoyer la commande {{order.ref}}</span>
      <span v-if="this.mode == 'libre'"> Envoi libre</span>
    </button>
    <modal :name="'tracking-modal'+order.id">
      <div class="modal-header">
        <h4 v-if="this.mode != 'libre'" class="text-left" style=""> Envoyer la commande {{order.ref}}</h4>
        <h4 v-if="this.mode == 'libre'" class="text-left" style=""> Envoi libre</h4>
     </div>
     <div class="modal-body">
       <h5 class="text-left">Transporteur</h5>
       <div style="width:100%">
         <select style="width:calc(50% - 20px); margin:10px; display:inline-block;float:left;" class="browser-default custom-select" v-model="carrier">
           <option v-if="this.mode != 'libre'" value="mainpropre">Remise en main propre</option>
           <option v-if="!this.order.array_options.options_retraitatelier" value="colissimo">Colissimo</option>
           <option v-if="!this.order.array_options.options_retraitatelier" value="ciblex">Ciblex</option>
           <option v-if="this.mode != 'libre' && !order.array_options.options_retraitatelier" value="autre">Autre</option>
         </select>
         <select style="width:calc(50% - 20px); margin:10px; display:inline-block;" class="browser-default custom-select"  v-if="carrier == 'ciblex'" v-model="carrierAccount">
           <option value="OTC">Compte OTC</option>
           <option value="BBP">Compte BBP</option>
            <option value="CF">Compte Centre-France</option>
             <option value="BP">Compte Bien Public</option>
              <option value="NR">Compte Nouvelle République</option>
               <option value="PROV">Compte La Provence</option>
         </select>
       </div>

              <div class="mt-3" style="clear:both" v-if="carrier == 'colissimo' || carrier == 'ciblex'">

                  <h5 class="text-left">Destinataire</h5>
                  <input  style="width:calc(100% - 20px); margin:10px; display:inline-block;" type="text" class="form-control" v-model="destinataire.name" name="" placeholder="Nom">
                  <textarea  style="width:calc(100% - 20px); margin:10px; display:inline-block;" name="name" class="form-control" rows="2" cols="20" v-model="destinataire.address" placeholder="Adresse"></textarea>
                  <input type="text" style="width:calc(100% / 3 - 20px); margin:10px;margin-left:0; display:inline-block;" class="form-control" v-model="destinataire.zip" name="" placeholder="Code postal">
                  <input type="text"  style="width:calc(100% / 3 - 20px); margin:10px; display:inline-block;" class="form-control" v-model="destinataire.city" name="" placeholder="Ville">
                  <select  style="width:calc(100% / 3 - 20px); margin:10px; display:inline-block;" class="browser-default custom-select" v-model="destinataire.countryCode">
                    <option value="FR">France</option>
                    <option value="BE">Belgique</option>
                    <option value="LX">Luxembourg</option>
                  </select>
                  <input type="email"  style="width:calc(50% - 20px); margin:10px; display:inline-block;" class="form-control" v-model="destinataire.email" name="" placeholder="Email">
                  <input type="text"  style="width:calc(50% - 20px); margin:10px; display:inline-block;" class="form-control" v-model="destinataire.phone" name="" placeholder="Téléphone">


              </div>
              <div class="mt-3" style="clear:both" v-if="carrier == 'autre'">

                  <h5 class="text-left">Numéro tracking <small>optionel</small></h5>
                  <input type="text" class="form-control" v-model="trackingcode_othercarrier" name="" placeholder="Tracking code">
              </div>

          <div class="mt-3" style="clear:both" v-if="carrier == 'colissimo' || carrier == 'ciblex'">
            <h5 class="text-left">Liste de colisage <button @click="addItemColisage">+</button></h5>
            <table class="table table-striped table-bordered">
              <thead class="thead-dark ">
                <tr>
                  <th class="text-center" width="75%">Colis</th>
                  <th class="text-center" width="20%">Poids en KG</th>
                  <th class="text-center" width="55%"></th>
                </tr>
              </thead>
              <tr v-for="(colis, index) in colisage" :key="colis.id">
                <td class="text-center">Colis {{index}}</td>
                <td class="text-center">
                  <input type="number" name="" class="form-control" v-model="colis.poids" step="0.1">
                </td>
                <td class="text-center">
                  <button class="btn btn-warning-round" @click="removeItemColisage(index)">
                    <i class="far fa-trash-alt"></i>
                  </button>
                </td>
              </tr>
            </table>
          </div>
        <div class="clearfix"></div>
    </div>

    <div class="modal-footer">
      <button type="button"  v-if="carrier == 'colissimo' || carrier == 'ciblex'" class="btnBlock btn btn-success" :disabled="validShippingAddress == false" @click="generateCarrierLabel" name="button">
        <i class="far fa-paper-plane"></i>
        Générer les étiquettes
      </button>
      <button type="button"  v-if="carrier == 'mainpropre' || carrier == 'autre'" class="btnBlock btn btn-success" @click="setCloture" name="button">
        <i class="far fa-paper-plane"></i>
        Classer envoyée
      </button>
      <button type="button" class="btnBlock btn btn-danger"  @click="hide" name="button">Annuler</button>
    </div>
    </modal>
  </div>

</template>

<script>
import axios from 'axios';
export default {
  name: "EnvoiColis",
  props : [
    "order",
    "mode"
  ],
  data() {
    return {
      tracking:"",
      carrier : "",
      trackingcode_othercarrier : "",
      destinataire : {},
      validShippingAddress : false,
      carrierAccount : 'BBP',
      colisage : [
        {
          "id": 0,
          "poids" :0
        }
      ]
    };
  },
  methods: {
    show () {
         this.$modal.show('tracking-modal'+this.order.id);
    },
    hide () {
         this.$modal.hide('tracking-modal'+this.order.id);
    },
    addItemColisage(){
      this.colisage.push({
        "id": Math.floor(Math.random() * (900 - 1 + 1) + 1),
        "poids" :0
      });
    },
    removeItemColisage(index){
      this.colisage.splice(index, 1);
    },
    generateCarrierLabel(){
          var posted = {};
      if(this.order.contact_livraison != null){ //si on a un contact de livraison alors marue blanche
         posted = {
          carrier : this.carrier,
          packing_list : this.colisage,
          expediteur : this.order.thirdparty.nom,
          destinataire : this.destinataire,
          refcmd : this.order.ref,
          carrierAccount: this.carrierAccount
        }
      }
      else{ //si meme destinataire que passeur de cmd alors envoyer en notre nom
           posted = {
            carrier : this.carrier,
            packing_list : this.colisage,
            expediteur : (this.carrierAccount == "OTC") ? "OTC-CONCEPT" : "BONBONPRINT / BOITEPERSO.COM",
            destinataire : this.destinataire,
            refcmd : this.order.ref,
            carrierAccount: this.carrierAccount
          }
      }
      axios
        .post(
          "https://bonbonprint.candyprint.eu/ws/generateCarrierLabel",
          posted
        )
        .then(response => {

          this.tracking = response.data[0];
          if(this.mode != "libre"){
            if(confirm("Le code de tracking "+this.tracking+" est il bien présent sur la première étiquette ?")){
              // set tracking code in Dolibarr Order
              axios.post("https://crm.bonbonprint.fr/api/index.php/bonbonprint/settrackingcode/"+this.order.id+"/"+this.tracking,
              {},
              {
                headers: {
                  'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
                }
              })
              .then( r =>{
                console.log(r);
                this.setCloture()
              })

            } else{
              alert("Envoi annulé, vous pouvez réessayer !");
            }
          }
          else{
            alert("Le numéro de suivi est le : "+this.tracking);
            this.$modal.hide('tracking-modal'+this.order.id);
          }

        });
    },
    setCloture(){
      if(this.carrier == "autre" && this.trackingcode_othercarrier != ""){
        axios.post("https://crm.bonbonprint.fr/api/index.php/bonbonprint/settrackingcode/"+this.order.id+"/"+this.trackingcode_othercarrier,
        {},
        {
          headers: {
            'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
          }
        })
        .then( r =>{
          console.log(r);
          axios.post("https://crm.bonbonprint.fr/api/index.php/orders/"+this.order.id+"/setcloture",
            {"tr" : 0},
          {
            headers: {
              'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
            }
          })
          .then( r =>{
            console.log(r);
             this.$modal.hide('tracking-modal'+this.order.id);
             this.order.statut = 3;
          })
        })
      }
      else{
        axios.post("https://crm.bonbonprint.fr/api/index.php/orders/"+this.order.id+"/setcloture",
        {"tr" : 0}, //0 == trigger execution 1 == no trigger
        {
          headers: {
            'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
          }
        })
        .then( r =>{
          console.log(r);
           this.$modal.hide('tracking-modal'+this.order.id);
           this.order.statut = 3;
        })
      }

    },
    validatorShippingAddress(){
      console.log(this.destinataire);
      if(this.destinataire.name.trim() == "")return false;
      if(this.destinataire.address.trim() == "")return false;
      if(this.destinataire.zip.trim() == "")return false;
      if(this.destinataire.city.trim() == "")return false;
      if(this.destinataire.phone == "" && this.destinataire.email == "" && this.destinataire.phonemobile == "")return false;
      return true;
    }
  },
  mounted(){
    if(this.order.array_options.options_retraitatelier)this.carrier = "mainpropre";
    if(this.order.contact_livraison != null){ //si on a un contact de livraison alors marue blanche
        this.destinataire = {
          name : this.order.contact_livraison.lastname+" "+this.order.contact_livraison.firstname,
          address: this.order.contact_livraison.address,
          zip : this.order.contact_livraison.zip,
          city : this.order.contact_livraison.town,
          countryCode : (this.order.contact_livraison.country_code != "") ? this.order.contact_livraison.country_code : "FR",
          email : (this.order.contact_livraison.email != "") ? this.order.contact_livraison.email : this.order.thirdparty.email,
          phone : this.order.contact_livraison.phone,
          phonemobile : this.order.contact_livraison.phone_mobile
        };
      }
    else if(this.order.thirdparty){
          this.destinataire = {
            name : this.order.thirdparty.nom+" "+this.order.thirdparty.name_alias,
            address: this.order.thirdparty.address,
            zip : this.order.thirdparty.zip,
            city : this.order.thirdparty.town,
            countryCode : (this.order.thirdparty.pays ) ? this.order.thirdparty.pays : "FR",
            email : this.order.thirdparty.email,
            phone : this.order.thirdparty.phone,
          };
    }
    else{
      this.destinataire = {
        name :"",
        address: "",
        zip : "",
        city :"",
        countryCode :"FR",
        email : "",
        phone : "",
      };
    }
    if(this.order.thirdparty.nom.toUpperCase() == "OTC CONCEPT" ||
      this.order.thirdparty.nom.toUpperCase() == "OTC PRINT" ||
      this.order.thirdparty.nom.toUpperCase() == "OTC-CONCEPT" ||
      this.order.thirdparty.nom.toUpperCase() == "OTC-PRINT"
    )this.carrierAccount = "OTC"
    else if(["2301","2300","2299","2298","2297","2296","2295","2294","2293","2292"].includes(this.order.thirdparty.id) == true)this.carrierAccount = "CF"
    else if(this.order.thirdparty.id == "2752")this.carrierAccount = "NR"
    else if(this.order.thirdparty.id == "2807")this.carrierAccount = "BP"
    else if(this.order.thirdparty.id == "2789")this.carrierAccount = "PROV"
    else if(this.order.thirdparty.id == "2827")this.carrierAccount = "GUIN"
    else  this.carrierAccount = "BBP"
   },
   watch : {
     destinataire: {
       handler: function () {
         this.validShippingAddress = this.validatorShippingAddress()
       },
       deep: true
     },
   },

}
</script>
