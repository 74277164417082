<template >
    <div v-if="thirdparty" class="col-12 col-sm-4">
      <div :style="'border: 1px solid '+bordercolor+';padding: 15px;margin: 10px;background:'+bgcolor">
        <h3 style="font-size:20px;font-weight:bold;">{{blocTitle}}</h3>
        <span style="font-weight:bold;">{{thirdparty.name}}<br/></span>
        <span v-if="thirdparty.name_alias">{{thirdparty.name_alias}}<br/></span>
        <span>{{thirdparty.address}}<br/></span>
        <span>{{thirdparty.zip }} {{thirdparty.town}}<br/></span>
        <span>{{thirdparty.country || "FRANCE"}}<br/></span>
        <hr v-if="thirdparty.phone || thirdparty.email"/>
        <span v-if="thirdparty.phone">{{$t("Phone")}} : {{thirdparty.phone}}<br/></span>
        <span v-if="thirdparty.email">{{$t("Email")}} : <a v-bind:href="'mailto:' + thirdparty.email">{{thirdparty.email}}</a></span>
      </div>
    </div>
</template>

<script>
export default {
  name : "Address",
  props : {
    blocTitle : String,
    thirdparty: Object,
    bgcolor : String,
    bordercolor : String
 }
}
</script>

<style lang="css" scoped>
</style>
