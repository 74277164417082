<template lang="html">
  <v-app-bar
    app
    color="dark"
    dark
    style="z-index: 50000000;"
  >

  <a class="navbar-brand ps-3" href="/"><img style="filter: brightness(0) invert(1);
width: 150px;" src="https://www.gea-invest.com/img/candyprint-logo-long.png" /></a>
  <v-btn icon color="white" id="sidebarToggle" @click="handleToogle()">
            <v-icon>fas fa-bars</v-icon>
          </v-btn>

    <v-spacer></v-spacer>
    <LocaleSwitcher />
    <v-btn
      href="/login"
      text
    >
      <span class="mr-2">{{ $t("Disconnect")}}</span>
      <v-icon>mdi-open-in-new</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
export default {
  name : "TopNav",
  components: {LocaleSwitcher},
  mounted () {
    if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
        document.body.classList.toggle('sb-sidenav-toggled');
    }
  },
  methods: {
      handleToogle : function(){
          document.body.classList.toggle('sb-sidenav-toggled');
          localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));

      }
  }
}
</script>

<style lang="css" scoped>
</style>
