<template>
  <div class="">
    <TopNav />
    <SideNav />
  <div id="home">

    <div id="page" class="container">
      <div v-if="loading" id="loader"><div class="loader"></div></div>
      <div class="hello">
        <h1>Commandes au départ</h1>
        <div class="row">
          <div class="col-lg-4 col-12">
            <div class="card">
              <div class="card-body row">
                <div class="col-3 text-center">
                  <i class="fas fa-cart-arrow-down colorpurple fa-lg"></i>
                </div>
                <div class="col-9">
                  <h4>Commandes</h4>
                  {{oftosend.length}} commandes au départ
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="headertoolstable">
          <EnvoiColis mode="libre" order="null"/>
        </div>
      <div class="card mb-4" id="bloctaborder">
        <div class="card-body">
          <v-data-table
            :headers="headers"
            :items="oftosend"
            item-key="NUMEROOF"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :footer-props="footerProps"
            :item-class="itemRowBackground"
          >
          <template v-slot:item.CMDID="{ item }">
                <a :href="'/order/'+ item.CMDID"><i class="far fa-eye"></i></a>
          </template>
          <template v-slot:item.DATEBESOIN="{ item }">
                {{ item.DATEBESOIN | moment }}
          </template>
        </v-data-table>
        </div>
      </div>
    </div>
  </div>
</div>

</div>
</template>


<script>
import axios from 'axios';
import moment from 'moment';
  import EnvoiColis from '@/components/buttons/envoicolis.vue';
  import SideNav from "@/components/navbar.vue";
  import TopNav from "@/components/topnav.vue";

export default {
  name: 'AuDepart',
  components: {EnvoiColis,SideNav,TopNav},
  data(){
    return {
      oftosend : [],
      headers: [
        {
          text: 'N°OF',
          value: 'NUMEROOF',
          sortable: true,
          align: 'left',
        },
        {
          text: 'Ref. Commande',
          value: 'REFCMD',
          sortable: true,
          align: 'left',
        },
        {
          text: 'Client',
          value: 'CLIENTNOM',
          sortable: true,
          align: 'left',
        },
        {
          text: 'DATE BESOIN',
          value: 'DATEBESOIN',
          sortable: true,
          align: 'left',
        },
        {
          text: '#',
          value: 'CMDID',
          sortable: true,
          align: 'left',
        },

      ],
      de:{},
      sortBy: 'CMDID',
      sortDesc: true,
      footerProps: {
        'items-per-page-options': [25, 50, 100, 500],
      },
    }
  },
  filters: {
    moment: function (date) {
      return (date) ? moment(date).format('DD/MM/YYYY') : "N.C.";
    }
  },
  mounted () {
    this.loading = true
    axios
      .get(
        "https://crm.bonbonprint.fr/api/index.php/of/listofaudepart",
        {
          headers: {
            'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
          }
        }
      )
      .then(response => {
        this.oftosend = response.data
        this.loading = false
        console.log(response);
      })
  },
  methods: {
    itemRowBackground: function (item) {
      console.log(item);
       return (item.retraitAtelier) ? 'bgRetraitAtelier' : 'bgClassic'
    }
  }


}
</script>

<style media="screen">
  .bgRetraitAtelier{
    background: #f7eecc;
  }

  #loader{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.8);
    z-index: 500000;
  }
  .loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 250px;
  height: 250px;
  transform: translate(-50%,-50%);
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
    animation: spin 2s linear infinite;
  }

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
