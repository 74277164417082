<template lang="html">
  <div class="statusContainer">

    <span v-if="this.statut == 'DRAFT'" class="badge badge-warning">{{$t("Waiting")}}</span>
    <span v-if="this.statut == 'VALID'" class="badge badge-primary">{{$t("ReadyToPrint")}}</span>
    <span v-if="this.statut == 'OPEN'" class="badge badge-info">{{$t("Processing")}}</span>
    <span v-if="this.statut == 'CLOSE'" class="badge badge-success">{{$t("OfClose")}}</span>
  </div>

</template>

<script>

export default {
  name: "StatutOf",
  props : [
    "statut"
  ],

}
</script>

<style lang="css" scoped>
.thumb-preview-item img{
  max-width: 200px;
  max-height: 200px;
}


</style>
<style media="screen">
.vm--modal{
  top: 10% !important;
  left: 15% !important;
  width: 80% !important;
  height: 80% !important;
}
</style>
