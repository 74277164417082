<template><div>
    <SideNav />
    <TopNav/>
  <div id="incident">
    <div id="page" class="container">

      <div class="card mb-4" id="bloctaborder">
        <div class="card-body">

          <v-data-table
            :headers="headers"
            :items="incidents"
            item-key="id"
            reactive
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
          >
          <template v-slot:top>
  <v-toolbar
    flat
  >
  <v-toolbar-title>{{$t("IncidentsTracker")}}</v-toolbar-title>
<v-divider
class="mx-4"
inset
vertical
></v-divider>
<v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="700px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="success"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
              {{$t("AddIncident")}}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{$t("Reporting")}}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="titre">{{$t("Title")}}</label>
                            <input type="text" class="form-control" v-model="newincident.titre" id="titre" name="titre" required="required" />
                        </div>
                        <div class="form-group">
                            <label for="machine">
                                {{$t("Printer")}}</label>
                            <select id="machine" name="machine" class="form-control" r v-model="newincident.machine" equired="required">
                                <option value="NA" selected="">Aucune</option>
                                <option value="Traceur MIG50">Traceur MIG50</option>
                                <option value="MiniCprinter">Mini CPrinter</option>
                                <option value="LEF20">LEF20</option>
                                <option value="Logiciel">Logiciels</option>
                            </select>
                        </div>

                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="description">
                                {{$t("Details")}}</label>
                            <textarea name="description" id="description" class="form-control" rows="5" cols="25" required="required"
                                  v-model="newincident.description"></textarea>
                        </div>
                    </div>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  text
                  @click="close"
                >
                  {{$t("Cancel")}}
                </v-btn>
                <v-btn
                  color="green darken-1"
                  text
                  :disabled="newincident.description == '' || newincident.titre == ''"
                  @click="generateIncident"
                >
                  {{$t("Valid")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
          <template v-slot:item.titre="{ item }">
            <strong>{{ item.titre}}</strong><br/>
            <small> {{item.description}}</small>
          </template>
          <template v-slot:item.date_creation="{ item }">
            {{item.date_creation | formatDate }}
          </template>
        </v-data-table>
      </div>
    </div>

  </div>
</div></div>
</template>


<script>
import axios from 'axios';
import moment from 'moment';
import SideNav from "@/components/navbar.vue";
import TopNav from "@/components/topnav.vue";
// @ is an alias to /src
export default {
  name: 'Incidents',
  components: {SideNav,TopNav},
  data(){
    return {
      dialog: false,
      editedIndex: -1,
      newincident: {
        titre: "",
        description: "",
        machine: "",
      },
      incidents : [],
      headers: [
        {
          text: 'ID',
          value: 'id',
          sortable: true,
          align: 'left',
        },
        {
          text: this.$i18n.t("Info"),
          value: 'titre',
          sortable: false,
          align: 'left',
        },
        {
          text: this.$i18n.t("Printer"),
          value: 'machine',
          sortable: true,
          align: 'left',
        },
        {
          text:this.$i18n.t("ReportingDate"),
          value: 'date_creation',
          sortable: true,
          align: 'left',
        },
      ],
      sortBy: 'id',
      sortDesc: true,
    }
  },
  created () {
    axios
      .get("https://bonbonprint.candyprint.eu/ws/listincident",)
      .then(response => {
        console.log(response);
        if(response.status == 200)this.incidents = response.data;
      })
  },
  methods: {
    show () {
         this.$modal.show('signalement');
    },
    hide () {
         this.$modal.hide('signalement');
    },
    generateIncident () {
      axios
        .post("https://bonbonprint.candyprint.eu/ws/addincident",this.newincident)
        .then(response => {
          console.log(response);
          this.incidents.push(response.data);
          this.newincident ={
            titre: "",
            description: "",
            machine: "",
          };
          this.close();
        })
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
  })
},
  },
  watch: {
    dialog (val) { val || this.close()},
  },
  filters: {
    formatDate: function (date) {
      return (date) ? moment(date).format('DD/MM/YYYY') : "N.C.";
    }
  },
}

</script>
