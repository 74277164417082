<template>
  <div> <TopNav/> <SideNav />
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <h2 class="text-center text-dark mt-5">{{ $t('Dashboard', { name: 'GEA-Invest'}) }}</h2>
        <div class="card my-5">

          <form class="card-body cardbody-color p-lg-5">

            <div class="text-center">
              <img src="img/logo.jpg" class="img-fluid profile-image-pic img-thumbnail rounded-circle my-3"
                width="200px" alt="profile">
            </div>
            <h3 class="text-center text-dark mt-5">{{$t("AddUser")}}</h3>
            <div class="mb-3">
              <input type="text" class="form-control" id="Username" v-model="input.name" aria-describedby="emailHelp"
                :placeholder="$t('User')">
            </div>
            <div class="mb-3">
              <input type="password" class="form-control" id="password" v-model="input.password"  :placeholder="$t('Password')">
            </div>
            <div class="mb-3">
              <input type="email" class="form-control" id="email" v-model="input.email"  :placeholder="$t('Email')">
            </div>
            <div class="text-center"><button type="button" class="btn btn-color px-5 mb-5 w-100"  :disabled="(!input.name || !input.password)" v-on:click="register()">{{$t("Register")}}</button></div>

          </form>
        </div>

      </div>
    </div>
  </div>
</div>
</template>


<script>
import axios from 'axios';
import SideNav from "@/components/navbar.vue";
import TopNav from "@/components/topnav.vue";
export default {
        name: 'Register',
        data() {
            return {
                input: {
                    name: "",
                    password: "",
                    email: ""
                }
            }
        },
        components: {SideNav,TopNav},
        methods: {
            register() {
                if(this.input.name != "" && this.input.password != "") {
                  axios
                    .post(
                      "https://bonbonprint.candyprint.eu/ws/signup",
                      this.input,
                    )
                    .then(response => {
                        console.log(response);
                        if(response.status == 201 || response.status == 200){
                          alert('OK');
                        }
                        else{
                          alert("Erreur d'enregistrement");
                        }
                    });
                } else {
                    console.log("A username and password must be present");
                }
            }
        }
    }

</script>
<style scoped>
.btn-color{
background-color: #0e1c36;
color: #fff;

}

.profile-image-pic{
height: 150px;
width: 150px;
object-fit: contain;
}



.cardbody-color{
background-color: #ebf2fa;
}

a{
text-decoration: none;
}
</style>
