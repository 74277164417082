<template lang="html">
  <div class="">
    <button class="btn btn-success" type="button" name="button" @click="show">
        <i class="fas fa-cog"></i>
      {{blockTitle}}
    </button>
    <modal :name="'preparation-modal-'+lineid+'-'+typeFile">
        <div class="modal-header">
            <h4 class="text-left">{{ $t('PreparationLine', { id: lineid}) }}</h4>
       </div>
       <div class="modal-body">
         <div class="row">
           <div class="col-12">
             <p class="text-left">{{productQtyLine}} {{$t("UnitOF")}} {{productRefLine}}</p>
             <h5 class="text-left">{{$t("FileToPrint")}}</h5>
             <div class="row">
               <div class="col-6">
                  <h4>{{typeFile}}</h4>
                  <img class="img-fluid" width="250px" :src="thumb" v-if='thumb != null && fileUploaded.srcurl == null' alt="">
                  <file-upload :btn-label="$t('SendFile')" accept=".pdf,.png,.jpg,.jpeg" :url='url' :thumb-url='thumbUrl' :headers="headers" @change="onFileChange"></file-upload>
               </div>
             </div>

           </div>
         </div>
         <div class="row">
           <div class="col-12">
             <input type="checkbox" id="checkbox" v-model="checked">
             <label for="checkbox">{{$t("CanBeUsedForPrinting")}}</label>
           </div>
         </div>
       </div>
       <div class="modal-footer">
         <button type="button" class="btn btn-danger" @click="hide" name="button">{{$t("Cancel")}}</button>
         <button type="button" class="btn btn-success" @click="valid" name="button">
           <i class="fas fa-check"></i>
           {{$t("Valid")}}
         </button>
       </div>
  </modal>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  name: "Preparation",
  props : [
    "lineid",
    "productQtyLine",
    "productRefLine",
    "blockTitle",
    "thumb",
    "typeFile"
  ],
  data() {
    return {
      printers: [],
      selectedPrinter : "",
      /* Service node a lancer sur serveur pour être fonctionnel*/
      url: 'https://bonbonprint.candyprint.eu/ws/upload/'+this.lineid,
      headers: {},
      fileUploaded:[],
      checked: true,
    };
  },
  methods: {
    valid : function(){
      console.log(this.typeFile);
      var of_config = {};
      if(this.typeFile == "RECTO"){
         of_config = {
          oflineid:this.lineid,
          printableFile : (this.checked) ? this.fileUploaded.srcurl : null,
          thumbnailUrl : this.fileUploaded.thumburl,
          done : 1,
        };
        axios
          .post(
            "https://crm.bonbonprint.fr/api/index.php/of/configureofline",
            of_config,
            {
              headers: {
                'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
              }
            }
          )
          .then(response => {
            this.$emit('prepadone',response);
            this.$modal.hide('preparation-modal-'+this.lineid+"-"+this.typeFile);
          });
      }else{
        of_config = {
          oflineid:this.lineid,
          white_printablefile : (this.checked) ? this.fileUploaded.srcurl : null,
          white_thumbnail : this.fileUploaded.thumburl,
        };
        axios
          .post(
            "https://crm.bonbonprint.fr/api/index.php/of/configureoflineverso",
            of_config,
            {
              headers: {
                'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
              }
            }
          )
          .then(response => {
            this.$emit('prepadone',response);
            this.$modal.hide('preparation-modal-'+this.lineid+"-"+this.typeFile);
          });
      }

    },
    show () {
         this.$modal.show('preparation-modal-'+this.lineid+"-"+this.typeFile);
    },
    hide () {
             this.$modal.hide('preparation-modal-'+this.lineid+"-"+this.typeFile);
    },
    onFileChange (file) {
     this.fileUploaded = file
     console.log(file);
   },

   thumbUrl (file) {
     return file.thumburl
   },

  },
}
</script>
