<template lang="html">
  <div class="">
    <button class="btn btn-success" type="button" name="button" @click="show">{{$t("Progress")}}</button>
    <modal :name="'printline-modal'+line.rowid">
      <div class="modal-header">
          <h4 class="text-left" style="">{{ $t('PrintLine', { id: line.rowid}) }}</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-4">
              <img :src="line.thumbnail" alt="" class="img-fluid">
          </div>
          <div class="col-8">
            <div class="form-group">
              <label for="">
                {{$t("PrintedQty")}}
                <input type="number" class="form-control" v-model="qtyprinted">
              </label>
              <span style="padding-left:20px;font-weight:700;">{{$t("AlreadyPrinted")}} {{line.qty_used}}/{{line.qty}}</span>
            </div>
            <div class="form-group">
              <label for="">
                {{$t("Waste")}}
                <input type="number" class="form-control" v-model="qtyperte">
              </label>
              <span style="padding-left:20px;font-weight:700;">{{$t("CurrentWaste")}} {{line.perte}}</span>
            </div>
          </div>

        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-6 text-left">
            <button class="btn btn-danger" type="button" name="button" @click="setEnd">
               {{$t("END")}}
            </button>
          </div>
          <div class="col-6 text-right">
            <button class="btn btn-success" style="margin:10px;" type="button" @click="setPrinted" name="button">
              <i class="fas fa-check"></i>
              {{$t("Valid")}}
            </button>
            <button class="btn btn-default" tyle="margin:10px;"  type="button" @click="hide" name="button">
              {{$t("Close")}}
            </button>
          </div>

        </div>



      </div>




  </modal>
  </div>

</template>

<script>
import axios from 'axios';

export default {
  name: "Impression",
  props : [
    "line",
    "ofid",
  ],
  data() {
    return {
      qtyprinted: 0,
      qtyperte :0,
    };
  },
  methods: {
    show () {
         this.$modal.show('printline-modal'+this.line.rowid);
    },
    hide () {
          this.$modal.hide('printline-modal'+this.line.rowid);
    },
    setPrinted() {
      var newQty = parseFloat(this.line.qty_used) + parseFloat(this.qtyprinted);
      var postedVar = {
        "line_id": this.line.rowid,
        "newqty": newQty,
        "perte" : parseFloat(this.qtyperte),
      }
      console.log(postedVar);
      axios
        .post(
          "https://crm.bonbonprint.fr/api/index.php/of/setprintqty",
          postedVar,
          {
            headers: {
              'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9'
            }
          }
        )
        .then(response => {
          console.log(response);
          this.qtyperte = 0;
          this.qtyprinted = 0;
          //if first print set start date
           this.$emit('impressiondone',response);
          // this.hide();
          this.$forceUpdate()
        });
    },

    setEnd(){
      if(confirm(this.$i18n.t('EndImpressionConfirm'))){
        axios
          .post(
            "https://crm.bonbonprint.fr/api/index.php/of/setlineend",
            {lineid:this.line.rowid},
            {
              headers: {
                'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9'
              }
            }
          )
          .then(response => {
            console.log(response);
            //if first print set start date
            this.$emit('impressionend',response);
            this.hide();
          });
        }
    }
  },
}
</script>

<style lang="css" scoped>
.thumb-preview-item img{
  max-width: 200px;
  max-height: 200px;
}


</style>
<style media="screen">
.vm--modal{
  top: 10% !important;
  left: 15% !important;
  width: 80% !important;
  height: 80% !important;
}
</style>
