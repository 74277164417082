var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SideNav'),_c('TopNav'),_c('div',{attrs:{"id":"home"}},[_c('div',{staticClass:"container",attrs:{"id":"page"}},[(_vm.loading)?_c('div',{attrs:{"id":"loader"}},[_c('div',{staticClass:"loader"})]):_vm._e(),_c('div',{staticClass:"hello"},[_c('h1',[_vm._v(_vm._s(_vm.$t("WaitingBATOrders")))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body row"},[_vm._m(0),_c('div',{staticClass:"col-9"},[_c('h4',[_vm._v(_vm._s(_vm.$t("Orders")))]),_vm._v(" "+_vm._s(_vm.orders.length)+" "+_vm._s(_vm.$t("WaitingBATOrders"))+" ")])])])])]),_c('div',{staticClass:"card mb-4",attrs:{"id":"bloctaborder"}},[_c('div',{staticClass:"card-body"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.orders,"item-key":"id","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"search":_vm.search,"footer-props":_vm.footerProps},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticStyle:{"display":"inline-block","width":"76%","margin-left":"2%","margin-right":"2%"},attrs:{"label":"Recherche"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"btn",staticStyle:{"display":"inline-block","width":"16%","margin-left":"2%","margin-right":"2%"},on:{"click":_vm.downloadCSVData}},[_vm._v(" "+_vm._s(_vm.$t("CSV"))+" ")])]},proxy:true},{key:"item.ref",fn:function(ref){
var item = ref.item;
return [_c('a',{class:{ 'batok': item.array_options.options_validclientbat },attrs:{"href":'/order/'+ item.id}},[_vm._v(_vm._s(item.ref))])]}},{key:"item.date_commande",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(item.date_commande)))]}},{key:"item.date_livraison",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(item.date_livraison)))]}},{key:"item.date_bat",fn:function(ref){
var item = ref.item;
return [(item.array_options.options_envoibat)?_c('span',[_vm._v(_vm._s(_vm._f("momentText")(item.array_options.options_envoibat)))]):_c('span',[_vm._v(_vm._s(_vm.$t("NotSend")))])]}},{key:"item.ofone",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.ofdone)+" / "+_vm._s(item.of.TAssetOFLine.length))]}},{key:"item.statut",fn:function(ref){
var item = ref.item;
return [(item.statut == 0)?_c('span',{staticClass:"badge badge-dark"},[_vm._v(_vm._s(_vm.$t("Draft")))]):_vm._e(),(item.statut == 1)?_c('span',{staticClass:"badge",class:item.array_options.options_validclientbat ? 'badge-info' : 'badge-warning'},[(!item.array_options.options_validclientbat)?_c('span',[_vm._v(_vm._s(_vm.$t("WaitingBAT"))+" "),(item.array_options.options_envoibat)?_c('span',[_vm._v(_vm._s(_vm.$t("ClientSide")))]):_vm._e()]):_vm._e(),(item.array_options.options_validclientbat)?_c('span',[_vm._v(_vm._s(_vm.$t("BATOKlong")))]):_vm._e()]):_vm._e(),(item.statut == 2)?_c('span',{staticClass:"badge badge-primary"},[_vm._v(_vm._s(_vm.$t("InProduction")))]):_vm._e(),(item.statut == 3)?_c('span',{staticClass:"badge badge-success"},[_vm._v(_vm._s(_vm.$t("End")))]):_vm._e()]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'/order/'+ item.id}},[_c('i',{staticClass:"far fa-eye"})])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-3 text-center"},[_c('i',{staticClass:"fas fa-cart-arrow-down colorpurple fa-lg"})])}]

export { render, staticRenderFns }