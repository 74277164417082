<template lang="html">
  <div class="">
    <button class="btn btn-success" type="button" name="button" @click="show">
      {{$t("SendToPrinter")}}
    </button>

    <modal :name="'printline-modal-'+line.rowid">
        <div class="modal-header">
            <h4 class="text-left" style="">{{$t("StartPrinting")}}</h4>
       </div>
       <div class="modal-body">
         <div class="row">
           <div class="col-6">
             <img :src="line.thumbnail" alt="" style="width:250px;margin:10px;">
             <img v-if="line.white_thumbnail" :src="line.white_thumbnail" alt="" style="width:250px;margin:10px;">
           </div>
           <div class="col-6">
             <p class="desctext">
               {{$t("ProductLabel")}} : {{line.product.label}}<br/>
               {{$t("Quantity")}} : {{line.qty_used}} / {{line.qty_needed}}<br/>
               {{$t("Printer")}} :
             <select v-model="line.machine" class="form-control">
                       <option v-for="printer in printers" :value="printer" :key="printer">
                     {{ printer }}
                 </option>
             </select>
             </p>
           </div>
         </div>
       </div>
       <div class="modal-footer">
         <button type="button" class="btn btn-success"  v-if='line.machine && line.printablefile != null && line.printablefile != "https://bonbonprint.candyprint.eu/" && line.printablefile != ""' @click="setFileDropbox" name="button">{{$t("Valid")}}</button>
         <button type="button" class="btn btn-success"  v-if='line.machine && (line.printablefile == null || line.printablefile == "https://bonbonprint.candyprint.eu/" || line.printablefile == "")' @click="setStart" name="button">{{$t("setStart")}}</button>
         <button type="button" class="btn btn-danger pushleft" @click="hide" name="button">{{$t("Cancel")}}</button>
       </div>
    </modal>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  name: "StartImpression",
  props : [
    "line",
    "orderRef",
    "parentPrinters"
  ],
  data() {
    return {
      printers: [],
    };
  },
  methods: {
    show () {

         this.$modal.show('printline-modal-'+this.line.rowid);
    },
    hide () {
          this.$modal.hide('printline-modal-'+this.line.rowid);
    },
    setFileDropbox(){
      console.log(this.line);
      if(this.line.machine == "PAS D'IMPRESSION"){
        axios
          .post(
            "https://crm.bonbonprint.fr/api/index.php/of/setlinestart",
            {lineid:this.line.rowid,machine : this.line.machine},
            {
              headers: {
                'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9'
              }
            }
          )
          .then(response => {
            console.log(response);
            axios
              .post(
                "https://crm.bonbonprint.fr/api/index.php/of/setlineend",
                {lineid:this.line.rowid},
                {
                  headers: {
                    'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9'
                  }
                }
              )
              .then(response => {
                console.log(response);
                //if first print set start date
                this.$emit('impressionend',response);
                this.hide();
              });
        });
      }
      else{
        console.log(this.line.printablefile);
        if(this.line.printablefile == null){
            this.$notify({
            group: 'foo',
            type: 'info',
            title: this.$i18n.t("ProductionInfo"),
            text: this.$i18n.t("FileAlreadyInDropbox"),
            duration:-1
          })
          this.setStart();
        }
        else{
          console.log(this.line.machine);
          axios.post(
            "https://bonbonprint.candyprint.eu/ws/moveFileToDB",
            {
              printerSelected : this.line.machine,
              fileLink : this.line.printablefile,
              secondFileLink : this.line.white_printablefile,
              productToPrint : this.line.product.ref,
              lineid : this.line.rowid,
              orderRef : this.orderRef
            }
          ).then( (r) => {
            if(this.line.white_printablefile){
              axios.post(
                "https://bonbonprint.candyprint.eu/ws/moveFileToDB",
                {
                  printerSelected : this.line.machine,
                  fileLink : this.line.white_printablefile,
                  productToPrint : this.line.product.ref,
                  lineid : this.line.rowid,
                  orderRef : this.orderRef
                }
              ).then( (r) => {
                console.log(r)
                if(r.status == 200){
                  this.$notify({
                    group: 'foo',
                    title: this.$i18n.t("SendToPrinter"),
                    text: "File "+r.data.result.name,
                    duration:-1
                  });
                  this.setStart();
                }
                else{
                  this.$notify({
                    group: 'foo',
                    type: 'warn',
                    title:  this.$i18n.t("SendToPrinterError"),
                    text:  this.$i18n.t("FileNotSend"),
                    duration:-1
                  });
                }
              })
              .catch((error) => {
                  console.log(error.response);
                  this.$notify({
                  group: 'foo',
                  type: 'warn',
                  title: this.$i18n.t("SendToPrinterError"),
                  text: error.response.data.error,
                  duration:-1
                })
              });
            }
            else{
              if(r.status == 200){
                this.$notify({
                  group: 'foo',
                  title: this.$i18n.t("SendToPrinter"),
                  text: "File "+r.data.result.name,
                  duration:-1
                });
                this.setStart();
              }
              else{
                this.$notify({
                  group: 'foo',
                  type: 'warn',
                  title:  this.$i18n.t("SendToPrinterError"),
                  text:  this.$i18n.t("FileNotSend"),
                  duration:-1
                });
              }
            }

          })
          .catch((error) => {
              console.log(error.response);
              this.$notify({
              group: 'foo',
              type: 'warn',
              title: this.$i18n.t("SendToPrinterError"),
              text: error.response.data.error,
              duration:-1
            })
          });
        }

      }
    },
    setStart(){
      //set start date of ofline for statistics purpose
      axios
        .post(
          "https://crm.bonbonprint.fr/api/index.php/of/setlinestart",
          {lineid:this.line.rowid,machine : this.line.machine},
          {
            headers: {
              'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9'
            }
          }
        )
        .then(response => {
          console.log(response);
          //if first print set start date
          this.$emit('impressiondone',response);
          this.hide();
        });
    },
  },
  mounted:  function(){
      if(this.parentPrinters){
        this.printers = this.parentPrinters
      }
      else{
        axios
          .get(
            "https://crm.bonbonprint.fr/api/index.php/workstation/listWk",
            {
              headers: {
                'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
              }
            }
          )
          .then(response => {
            this.printers = response.data
          });
      }

    },
}
</script>

<style lang="css" scoped>
.thumb-preview-item img{
  max-width: 200px;
  max-height: 200px;
}
</style>
