<template>
  <div>
    <TopNav/>
    <SideNav />
    <div id="page" class="container pageorder">
      <div class="card">
        <div class="card-title">
              <h1>New order form</h1>
        </div>
        <div class="card-body">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="order.ref"
                    :label="$t('OrderRef')"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="order.ref_client"
                    :label="$t('OrderRefClient')"
                    required
                  ></v-text-field>

                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="order.datel"
                          :label="$t('DeliveryDate')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="order.datel"
                        :min="today"
                      ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-textarea
                       name="input-7-1"
                       filled
                       :label="$t('Notes')"
                       auto-grow
                       v-model="order.notes"
                     ></v-textarea>
                </v-col>
              </v-row>
          </v-container>
          <hr>
        </div>
        <div class="card-body">
            <v-container>
              <v-row>
                <v-col>
                  Client :
                  <v-radio-group v-model="customerMode" @change="resetSelect">
                    <v-radio
                      key="0"
                      :label="$t('NewCustomer')"
                      value="0"
                    ></v-radio>
                    <v-radio
                      key="1"
                      :label="$t('ExistingCustomer')"
                      value="1"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <div class="" v-if="customerMode == '1'">
                  <v-autocomplete
                    v-model="order.thirdparty_id"
                    :items="thirdpartiesList"
                    dense
                    filled
                    item-value="id"
                    :item-text="beautifyNameThirdpartySelect"
                    :label="$t('Thirdparty')"
                  ></v-autocomplete>
                </div>
                <div class="" v-if="customerMode == '0'">
                  <v-text-field
                    v-model="order.thirdparty.name"
                    :label="$t('CustomerName')"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="order.thirdparty.address"
                    :label="$t('CustomerAddress')"
                  ></v-text-field>
                  <v-text-field
                    v-model="order.thirdparty.zip"
                    :label="$t('CustomerZip')"
                  ></v-text-field>
                  <v-text-field
                    v-model="order.thirdparty.town"
                    :label="$t('CustomerTown')"
                  ></v-text-field>
                </div>
              </v-row>
            </v-container>
            <hr>
          </div>
      <div class="card-body">
        <v-container>

    <v-row class="previous"
     v-for="(line, counter) in order.lines"
     v-bind:key="counter">
      <v-col cols="6">
        <v-autocomplete
          v-model="line.fk_product"
          :items="productsList"
          dense
          filled
          item-value="id"
          :item-text="beautifyProductLabelSelect"
          :label="$t('Product')"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="line.qty"
          single-line
          min="1"
          type="number"
        />
      </v-col>
      <v-col cols="2">
        <v-btn class="btn btn-danger" v-if="order.lines.length > 1" @click="deleteLine(counter)">{{ $t('DeleteLine')}}</v-btn>
      </v-col>


    </v-row>
    <v-btn class="btn btn-primary" @click="addLine">{{ $t('AddNewLine')}}</v-btn>
    <hr>
            </v-container>
        </div>

        <v-btn :disabled="!valid" @click="addTheOrder" class="btn btn-success" type="button" name="button">{{ $t('AddOrder')}}</v-btn>
      </div>

      </div>
  </div>
</template>
<script>
  import axios from 'axios';
  import moment from 'moment';
  import SideNav from "@/components/navbar.vue";
  import TopNav from "@/components/topnav.vue";
  export default {
    name: 'OrderAddForm',
    components: {
      SideNav,
      TopNav
    },
    data() {
      return {
        valid : false,
        menu: '',
        customerMode: '1',
        thirdpartiesList : [],
        productsList :[],
        order:{
            ref: '',
            ref_client: '',
            thirdparty_id: null,
            thirdparty: {
              name :'',
              address : '',
              zip: '',
              town: ''
            },
            lines : [{
              fk_product:'',
              qty: ''
            }],
            datel : '',
            notes : ''
        },
        today : moment().format('YYYY-MM-DD'),
      };
    },
    mounted: function() {
      axios
        .get(
          "https://crm.bonbonprint.fr/api/index.php/thirdparties",
          {
            headers: {
              'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
            }
          }
        )
        .then(response => {
          this.thirdpartiesList = response.data
        });
        axios
          .get(
            "https://crm.bonbonprint.fr/api/index.php/products",
            {
              headers: {
                'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
              }
            }
          )
          .then(response => {
            this.productsList = response.data
          });
    },
    methods: {
      beautifyNameThirdpartySelect : function(t) {
        return (t.zip && t.town) ? t.name+' ('+t.zip+' '+t.town+')' : t.name;
      },
      beautifyProductLabelSelect : function(p) {
        return p.ref+' '+p.label;
      },
      resetSelect: function(){
        this.order.thirdparty_id = null;
      },
      addLine(){
        this.order.lines.push({
          fk_product:'',
          qty: ''
        });
      },
      deleteLine(counter){
        this.order.lines.splice(counter,1);
      },
      addTheOrder : function(){
        axios
          .post(
            "https://crm.bonbonprint.fr/api/index.php/orders/postfromprodapp",
            this.order,
            {
              headers: {
                'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
              }
            }
          )
          .then(response => {
    
            this.$router.push({ path: '/order/'+response.data});
          });
      }
    },
    watch : {
      order: {
        handler: function () {

          let valid = true;
          if(!this.order.ref || !this.order.datel)valid = false;

          if(parseInt(this.customerMode) == 0)
            if(!this.order.thirdparty.name) valid = false;
          else if (parseInt(this.customerMode) == 1)
            if(!this.order.thirdparty_id) valid=false;

          this.order.lines.forEach((item) => {
            if(!item.fk_product || !item.qty){
              valid = false;
            }
            if( item.quantity <= 0){
              valid = false;
            }
          });

          console.log(this.order);
          this.valid = valid;

        },
        deep: true
      },
    },
  }
</script>
