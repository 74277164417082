<template>
  <div> <TopNav/> <SideNav />
  <div id="home">
    <div id="page" class="container">
    <div v-if="loading" id="loader"><div class="loader"></div></div>
      <div class="hello">
        <h1>{{$t("InProductionOrders")}}</h1>
        <div class="row">
          <div class="col-lg-4 col-12">
            <div class="card">
              <div class="card-body row">
                <div class="col-3 text-center">
                  <i class="fas fa-cart-arrow-down colorpurple fa-lg"></i>
                </div>
                <div class="col-9">
                  <h4>{{$t("Orders")}}</h4>
                  {{oftotal}} {{$t('InProductionOrders')}}
                </div>
              </div>
            </div>
          </div>
        </div>

      <div class="card mb-4 bloctabprod" id="bloctaborder">
        <div class="card-body">
          <v-card>
            <v-card-title>
               <v-text-field
                v-model="filters.search"
                :label="$t('Search')"
                single-line
                hide-details
              ></v-text-field>
              <v-select
                :items="printers"
                multiple
                :label="$t('Printer')"
                item-text='1'
                item-value='1'
                v-model="filters.printed_with"
                ></v-select>
                <v-btn class="btn btn-info" @click="filterPrinter">
                  {{$t("Search")}}
                </v-btn>
                <v-btn class="btn" @click="downloadCSVData">
                  {{$t("CSV")}}
                </v-btn>

            </v-card-title>
          <table class="table" style="font-size:14px;">
            <thead>
              <tr>
                <th scope="col">{{$t("Action")}}</th>
                <th scope="col">{{$t("Visual")}}</th>
                <th scope="col">{{$t("OrderRef")}}</th>
                <th scope="col">{{$t("Customer")}}</th>
                <th scope="col">{{$t("ProductRef")}}</th>
                <th scope="col">{{$t("ProductLabel")}}</th>
                <th scope="col" width="70px">{{$t("Qty")}}</th>
                <th scope="col">{{$t("Printer")}}</th>
                <th scope="col">{{$t("Status")}}</th>
                <th scope="col" width="95px">#</th>
              </tr>
            </thead>
            <tbody  v-for="(o,$index) in orders" :key="o.id">
              <OLine :class="'linecolor-'+ $index % 2" v-for="item in o.of.TAssetOFLine" :key="item.id" :o="o" :item="item" :parentPrinters="parentPrinters" @anItemIsEnded="itemindex => anItemIsEnded($index,itemindex)"/>
            </tbody>
          </table>
          <v-pagination
          v-model="page"
          :length="Math.trunc(this.totalOrderNumber/this.orderPerPage) + 1"
          circle
        ></v-pagination>
        </v-card>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</template>
<script>
// @ is an alias to /src
import axios from 'axios';
import moment from 'moment'
import OLine from "@/components/OfLineArray.vue"
import SideNav from "@/components/navbar.vue";
import TopNav from "@/components/topnav.vue";
export default {
  name: 'Home',
  components: {
      OLine,
      SideNav,
      TopNav,
  },
  data(){
    return {
       loading: false,
      selected: [],
      printers: [],
      parentPrinters:[],
      search: "",
      orders : [],
      fullOrdersList: [],
      ordersListNotSlice: [],
      ofdone: 0,
      totalOrderNumber : 0,
      paginationLength: 0,
      up: 0,
      oftotal: 0,
      loaded : false,
      sortBy: 'date_commande',
      sortDesc: true,
      page: 1,
      blockHandler: false,
      orderPerPage: 10,
      footerProps: {
        'items-per-page-options': [25, 50, 100, 500],
      },
      filters: {
        search: '',
        printed_with: (this.$route.params.printer) ? [this.$route.params.printer] : ''
      },
    }
  },
  mounted () {
      this.loading = true
      axios
        .get(
          "https://crm.bonbonprint.fr/api/index.php/workstation/listWk",
          {
            headers: {
              'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9'
            }
          }
        )
        .then(response => {
          this.printers = Object.keys(response.data).map((key) => [Number(key),response.data[key]]);
          this.parentPrinters= response.data
        });
    axios
      .get(
        "https://crm.bonbonprint.fr/api/index.php/orders/orderslistwithofs?sortfield=t.date_livraison&sortorder=ASC&limit=1000&page=0&sqlfilters=(t.fk_statut:=:'2')",
        {
          headers: {
            'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
          }
        }
      )
      .then(response => {
        console.log(response);
        // if(this.page == 1){
        //   this.orders = response.data.olist.slice(0,this.orderPerPage);
        // }
        // else{
        //   this.orders = response.data.olist.slice(this.orderPerPage*(this.page -1),this.orderPerPage*this.page);
        // }

        this.fullOrdersList = response.data.olist
        this.loaded = true
        this.oftotal = response.data.total
        this.filterPrinter()
        this.loading = false
      })
  },
  filters: {
    moment: function (date) {
      return (date) ? moment(date*1000).format('DD/MM/YYYY') : "N.C.";
    }
  },
  methods: {
    handleprint : function(r){
      var orderIndex = this.orders.findIndex((obj => parseInt(obj.of.id) == r.data.fk_assetOf))
      var objIndex = this.orders[orderIndex].of.TAssetOFLine.findIndex((obj => parseInt(obj.id) == r.data.id));

      var dack = this.orders[orderIndex].of.TAssetOFLine[objIndex];
      this.orders[orderIndex].of.TAssetOFLine[objIndex] = r.data
      this.orders[orderIndex].of.TAssetOFLine[objIndex].orderline = dack.orderline

      this.$forceUpdate();
   },
   handleCloseOf : function(r){
     var orderIndex = this.orders.findIndex((obj => parseInt(obj.of.id) == r))
     this.orders.splice(orderIndex, 1)
   },

  // loadOrders(){
  //    axios
  //      .get(
  //        "https://crm.bonbonprint.fr/api/index.php/orders/orderslistwithofs?sortfield=t.date_livraison&sortorder=ASC&limit=2000&sqlfilters=(t.fk_statut:=:'2')",
  //        {
  //          headers: {
  //            'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
  //          }
  //        }
  //      )
  //      .then(response => {
  //        console.log(response);
  //        // this.orders = response.data.olist
  //        if(this.page == 1){
  //          this.orders = response.data.olist.slice(0,this.orderPerPage);
  //        }
  //        else{
  //          this.orders = response.data.olist.slice(this.orderPerPage*(this.page -1),this.orderPerPage*this.page);
  //        }
  //        this.fullOrdersList = response.data.olist
  //        this.oftotal = response.data.total[0].TOTAL
  //        this.filterPrinter()
  //      })
  // },
  filterPrinter() {
    const that = this;
    if (this.filters.printed_with !== [] && this.filters.printed_with != '') {
      this.orders = this.fullOrdersList.filter((item) => item.of.TAssetOFLine.some( e => this.filters.printed_with.includes(e.machine)))
      this.orders.forEach((item) => {
        item.of.TAssetOFLine = item.of.TAssetOFLine.filter(e => (this.filters.printed_with.includes(e.machine) && !(e.printed == 'true' || e.printed == true)));
      });
    }
    else{
      this.orders = this.fullOrdersList;
    }
    if(this.filters.search.trim() != ''){

      this.orders = this.orders.filter( function(e) {

        if(e.ref.toUpperCase().includes(that.filters.search.toUpperCase())) return true;
        else if(e.ref_client)
          if(e.ref_client.toUpperCase().includes(that.filters.search.toUpperCase()))
            return true;
        else if(e.client.name.toUpperCase().includes(that.filters.search.toUpperCase()))return true;
      });
    }
    this.totalOrderNumber = this.orders.length;

    this.blockHandler = true;
    //if(this.totalOrderNumber < that.page * this.orderPerPage)that.page = 1;
    while(this.totalOrderNumber + this.orderPerPage < that.page * this.orderPerPage && that.page > 1)that.page--
    this.page = that.page;
    this.blockHandler = false;
    this.ordersListNotSlice = this.orders;
    if(this.page == 1){
      this.orders = this.orders.slice(0,this.orderPerPage);
    }
    else{
      this.orders = this.orders.slice(this.orderPerPage*(this.page -1),this.orderPerPage*this.page);
    }
    console.log(this.orders);
  },
  anItemIsEnded(index,itemid) {
    this.orders[index].of.TAssetOFLine = this.orders[index].of.TAssetOFLine.filter(function( obj ) {
      return (obj.id != itemid && !obj.date_end_line);
    });
    axios.get(
        "https://crm.bonbonprint.fr/api/index.php/of/howmanylinenotprinted/"+this.orders[index].of.id,
        {
          headers: {
            'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
          }
        }
      ).then( response => {
        if(response.data[0].total == 0){
          if(this.orders[index].of.TAssetOFLine.length == 0){
            axios.post(
              "https://crm.bonbonprint.fr/api/index.php/of/terminersimpleOF",
              {ofid:parseInt(this.orders[index].of.id),lines:[]},
              {
                headers: {
                  'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
                }
              }
            )
            .then(response => {
              console.log(response);
              this.orders.slice(index);
              //var ref = this.orders[index].ref;
              this.$notify({
                group: 'foo',
                title: this.$i18n.t("OrderEnded"),
                text: this.$i18n.t("AllOfEnded"),
                duration:-1
              });
            });
          }
        }
      });
  },
  downloadCSVData() {
      let csv = 'Ref commande;Ref client;Tiers;Entrepot de départ;Date de saisie; Date de livraison; Contact de Livraison; Adresse de livraison; Code postal de livraison; Ville livraison; Ref produit; Libéllé produit;Quantité;\n';
      this.ordersListNotSlice.forEach((o) => {
        var  lineCsv = "";
        var  tempCsv = "";
        tempCsv += o.ref;
        tempCsv += ";"+o.ref_client;
        tempCsv += ";"+o.client.name;
        tempCsv += ";"+o.array_options.options_otcwarehouse;
        tempCsv += ";"+ moment(o.date_commande*1000).format('DD/MM/YYYY');
        tempCsv += ";"+ moment(o.date_livraison*1000).format('DD/MM/YYYY');
        if(o.contact_livraison.length > 0){
          tempCsv += ";"+ (o.contact_livraison.lastname) ? o.contact_livraison.lastname.replace(/(\r\n|\n|\r)/gm, "") : '';
          tempCsv += ";"+(o.contact_livraison.address) ? o.contact_livraison.address.replace(/(\r\n|\n|\r)/gm, "") : '';
          tempCsv += ";"+(o.contact_livraison.zip) ? o.contact_livraison.zip : '';
          tempCsv += ";"+(o.contact_livraison.town) ? o.contact_livraison.town : '';

        }
        else{
          tempCsv += ";"+o.client.name.replace(/(\r\n|\n|\r)/gm, "");
          tempCsv += ";"+o.client.address.replace(/(\r\n|\n|\r)/gm, "");
          tempCsv += ";"+o.client.zip;
          tempCsv += ";"+o.client.town;
        }

        console.log(o.of.TAssetOFLine.length);
        o.of.TAssetOFLine.forEach((item) => {
          lineCsv = "";
          lineCsv += ";"+item.product.ref;
          lineCsv += ";"+item.product.label
          lineCsv += ";"+item.qty
          lineCsv += "\n";
          csv += tempCsv+lineCsv;
        });

      });

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'nameYourFileHere.csv';
      anchor.click();
  }
 },
 watch : {
   page: {
     handler: function () {
       if(this.blockHandler == false)this.filterPrinter()
     },
     deep: true
    },
  },

}
</script>
<style media="screen">
  .linecolor-0{
    background: #F2E6E7;
  }
  .linecolor-1{
    /*background: #c0e0f3;*/
  }

  #loader{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.8);
    z-index: 500000;
  }
  .loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 250px;
  height: 250px;
  transform: translate(-50%,-50%);
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
    animation: spin 2s linear infinite;
  }


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
