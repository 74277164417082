<template>
          <div id="page" class="container pageorder">
            <div class="card">
             <div class="card-body">
              <h1>
                {{$t("OrderRef")}} {{order.ref}}
                <span v-if="order.ref_client" style="color:#666;font-size:0.8em;">({{order.ref_client}})</span>
              </h1>
              <div v-if="order.array_options">
                <div v-if="order.array_options.options_origintmid">{{$t("OIDTMB:")}} {{order.array_options.options_origintmid}}<br/></div>
                <div v-if="order.array_options.options_oidotc">{{$t("OIDOTC:")}} : {{order.array_options.options_oidotc}}<br/></div>
                <div v-if="order.id">{{$t("DOLID:")}} {{order.id}}<br/></div>
                <div>{{$t("StatutOf:")}} <StatutOf :statut="of.status"/></div>
                <p v-if="order.array_options.options_numerosuivi"> {{$t("TrackingNumber")}}
                  <a v-if="order.array_options.options_numerosuivi.toUpperCase().indexOf('6A') == 0" target="_blank" :href="'https://www.laposte.fr/outils/suivre-vos-envois?code='+order.array_options.options_numerosuivi">{{order.array_options.options_numerosuivi}} <!-- COLISSIMO --></a>
                  <a v-else-if="order.array_options.options_numerosuivi.length == 14" target="_blank" :href="'http://extranet.ciblex.fr/extranet/client/corps.php?module=colis&colis='+order.array_options.options_numerosuivi">{{order.array_options.options_numerosuivi}} <!-- CIBLEX --></a>
                  <span v-else>{{order.array_options.options_numerosuivi}}<!--AUTRE --> </span>
                </p>

              </div>
             </div>
          </div>

          <div class="row" style="margin-top:20px;">
            <div v-if="order.array_options" class="col-sm-4 col-12">
              <!-- eslint-disable -->
              <div style="border: 1px solid #ccc; padding: 15px;margin:10px;">
                <h2 style="font-size:20px;font-weight:bold;">{{$t("Options")}}</h2>
                <span>{{$t("Warehouse")}}
                  <span v-if="order.array_options.options_otcwarehouse == '0' || order.array_options.options_otcwarehouse == '59ccac780abc817c4e0890e7' ">OTC</span>
                  <span v-else-if="order.array_options.options_otcwarehouse == '5f8efb6386eba06557c88cf8' ">AlphaLogistic</span>
                  <br/>
                </span>
                <span>{{$t("DeliveryDate")}} {{ order.date_livraison | moment }}<br/></span>
                <span v-if="order.array_options.options_envoibat">
                  {{$t("LastBatSendOn")}} {{ order.array_options.options_envoibat | momentText}}<br/></span>
                <span v-if="order.array_options.options_validclientbat">  {{$t("BatValidOn")}} {{ order.array_options.options_validclientbat | momentText}}<br/></span>

                <label for="retraitAtelier">
                  <input type="checkbox" id="retraitAtelier" value="i1" class=""
                  v-model="order.array_options.options_retraitatelier" @change="changeRetraitAtelier()">
                  {{$t("WarehouseWithdraw")}}
               </label>

                <div class="text-center">
                  <button style="margin:5px;" class="btn btn-default btn-sm" type="button" @click="envoiClient" name="button">{{$t("BATSendToCustomer")}}</button>
                  <button style="margin:5px;" v-if="order.array_options.options_envoibat" class="btn btn-default btn-sm" type="button" @click="batValiderClient" name="button">{{$t("BATValidByCustomer")}}</button>

                </div>



              </div>
              <!-- eslint-enable -->
            </div>
            <Address bgcolor="#FFF" bordercolor="#CCC" v-if="order.thirdparty" :blocTitle="$t('ClientAddress')" :thirdparty="order.thirdparty"/>
              <Address bgcolor="#FFF" bordercolor="#CCC" v-if="!order.contact_livraison && !order.array_options.options_retraitatelier" :blocTitle="$t('DeliveryAddress')" :thirdparty="order.thirdparty"/>
              <Address bgcolor="#f4c6cb" bordercolor="#f4c6cb" v-if="order.contact_livraison && !order.array_options.options_retraitatelier" :blocTitle="$t('DeliveryAddress')" :thirdparty="order.contact_livraison"/>
          </div>
          <div class="row">
            <div class="col-12" v-if="order.note_private || order.note_public">
              <div style="border: 1px solid #ccc; padding: 15px;margin:10px;">
                <h4>{{$t("MoreInfo")}}</h4>
                <span style="white-space: pre-wrap; word-wrap: break-word;" v-if="order.note_private">{{ order.note_private }}</span>
                <span style="white-space: pre-wrap; word-wrap: break-word;" v-if="order.note_public">{{ order.note_public }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th width="2%">#</th>
                    <th width="25%" class="text-center">{{$t("Visual")}}</th>
                    <th width="10%" class="text-center">{{$t("ProductRef")}}</th>
                    <th width="20%" class="text-center">{{$t("ProductLabel")}}</th>
                    <th width="13%" class="text-center">{{$t("Quantity")}}</th>
                    <th width="20%" class="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  <OfLine v-for="ofline in of.TAssetOFLine" :key="ofline.id" :line="ofline" :ofstatus="of.status"  :orderRef="of.ref" @updated="handleUpdate" @anItemIsEnded="handleItemEnd"/>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right">
              <SendToProduction v-if="of.status == 'DRAFT'" :ofid="of.id" :orderRef="of.ref" @validated="sendToProduction" />
              <PrintOfPdf :ofid="of.id"/>
              <FinProduction v-if="of.status != 'DRAFT' && of.status != 'CLOSE'" :ofid="of.id" @closeOf="ofClosed"/>
              <EnvoiColis v-if="of.status == 'CLOSE' && parseInt(order.statut) != 3" :order="order"/>
            </div>
          </div>
      </div>
</template>
<script>
  import axios from 'axios';
  import moment from 'moment';
  import Address from '@/components/Address.vue'
  import OfLine from '@/components/OfLine.vue'
  import SendToProduction from '@/components/buttons/sendToProduction.vue'
  import PrintOfPdf from '@/components/buttons/printOf.vue'
  import EnvoiColis from '@/components/buttons/envoicolis.vue'
  import StatutOf from '@/components/buttons/statutOf.vue'
  import FinProduction from '@/components/buttons/finproduction.vue'
  export default {
    name: 'OrderCard',
    props: ["orderid"],
    components: {
      Address,
      OfLine,
      SendToProduction,
      PrintOfPdf,
      StatutOf,
      EnvoiColis,
      FinProduction
    },
    data() {
      return {
        order: {},
        of: [],
        preparationValid : false,
      };
    },
    mounted: function() {
      axios
        .get(
          "https://crm.bonbonprint.fr/api/index.php/orders/detailled/"+this.orderid,
          {
            headers: {
              'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
            }
          }
        )
        .then(response => {
          this.order = response.data
        });
        axios
          .get(
            "https://crm.bonbonprint.fr/api/index.php/of/orderof/"+this.orderid,
            {
              headers: {
                'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
              }
            }
          )
          .then(response => {

            this.of = response.data
          });
    },
    filters: {
      moment: function (date) {
        return (date) ? moment(date*1000).format('DD/MM/YYYY') : "N.C.";
      },
      momentText: function (date) {
        return (date) ? moment(date).format('DD/MM/YYYY') : "N.C.";
      }
    },
    methods: {
      handleUpdate : function(r) {
        var objIndex = this.of.TAssetOFLine.findIndex((obj => obj.id == r.id));
        this.of.TAssetOFLine[objIndex] = r;
        this.checkPreparationValidity();
      },
      checkPreparationValidity : function(){
        this.preparationValid = true; //temporaly enable
        // var isUnvalid = this.of.TAssetOFLine.find(e => e.printablefile == "");
        // if(isUnvalid){
        //   console.log("Invalid prep");
        // }
        // else{
        //   console.log("valid prep");
        //   this.preparationValid = true;
        // }
      },
      envoiClient: function(){
        axios
          .post(
            "https://crm.bonbonprint.fr/api/index.php/orders/"+this.orderid+"/setEnvoyerclient",
            {},{
              headers: {
                'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
              }
            }
          )
          .then(response => {
            console.log(response);
            if(response.data.success){
              this.reload();
            }

            //  this.of = response.data
          });
      },
      batValiderClient: function(){
        axios
          .post(
            "https://crm.bonbonprint.fr/api/index.php/orders/"+this.orderid+"/setBATValidClient",
            {},{
              headers: {
                'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
              }
            }
          )
          .then(response => {
            console.log(response);
            if(response.data.success){
              this.reload();
            }

            //  this.of = response.data
          });
      },
      sendToProduction: function(){
        this.checkPreparationValidity();
        if(this.preparationValid == false){
          alert(this.$i18n.t("InvalidPreparation"));
          return;
        }
        this.of.status = "VALID"
        this.$forceUpdate();
      },
      ofClosed: function(){
        this.of.status = "CLOSE"
        this.$forceUpdate();
      },
      handleItemEnd: function(){},
      changeRetraitAtelier: function(){
        if(this.order.array_options.options_retraitatelier == true){
          axios
            .post(
              "https://crm.bonbonprint.fr/api/index.php/orders/"+this.orderid+"/setRetraitAtelier",
              {},{
                headers: {
                  'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
                }
              }
            )
            .then(response => {
              console.log(response);
              if(response.data.success){
                this.reload();
              }
            });
        }
        else{
          axios
            .post(
              "https://crm.bonbonprint.fr/api/index.php/orders/"+this.orderid+"/unsetRetraitAtelier",
              {},{
                headers: {
                  'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
                }
              }
            )
            .then(response => {
              console.log(response);
              if(response.data.success){
                this.reload();
              }
            });
        }
        console.log(this.order.array_options.options_retraitatelier);
      },
      reload: function() {
        axios
          .get(
            "https://crm.bonbonprint.fr/api/index.php/orders/detailled/"+this.orderid,
            {
              headers: {
                'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
              }
            }
          )
          .then(response => {
            this.order = response.data
          });
          axios
            .get(
              "https://crm.bonbonprint.fr/api/index.php/of/orderof/"+this.orderid,
              {
                headers: {
                  'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
                }
              }
            )
            .then(response => {

              this.of = response.data
            });
      },
    },
  }
</script>
