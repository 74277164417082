<template >
  <tr>
    <td style="text-align:center;vertical-align: middle;"></td>
    <td style="text-align:center;vertical-align: middle;" class="text-center">
      <img v-if="line.thumbnail != ''" :src="line.thumbnail" alt="" style="width:100px;margin:2px;">
      <img v-if="line.white_thumbnail != ''" :src="line.white_thumbnail" alt="" style="width:100px;margin:2px;">
      <!-- <img v-else-if="line.thumbnail == '' && line.orderline.array_options.options_visuel" :src="line.orderline.array_options.options_visuel" style="max-width:250px" alt=""> -->

      <a :href="line.printablefile" target="_blank"  v-if="(ofstatus == 'OPEN' || ofstatus == 'VALID') " download>{{$t("DownloadPrintableFile")}}</a>
      <a :href="line.white_printablefile" target="_blank"  v-if="(ofstatus == 'OPEN' || ofstatus == 'VALID') && line.white_printablefile" download>{{$t("DownloadPrintableFileVerso")}}</a>

    </td>
    <td style="text-align:center;vertical-align: middle;">{{line.product.ref}}</td>
    <td style="text-align:center;vertical-align: middle;">
      {{line.product.label}}
      <span  style="white-space: pre-wrap; word-wrap: break-word" :if="line.orderline.desc != ''" v-html="line.orderline.desc"></span>
    </td>
    <td style="text-align:center;vertical-align: middle;">{{line.qty_used}} / {{line.qty}}
    <br/>
    <span v-if="line.qty_used >= line.qty"> {{$t("Waste")}} : {{line.qty_used - line.qty}}</span></td>
    <td style="text-align:center;vertical-align: middle;">
      <Preparation @prepadone="preparationDone" :blockTitle="$t('Prepare')" :lineid="line.rowid" v-if="ofstatus == 'DRAFT' && line.done != 'true'" :productQtyLine="line.qty" :productRefLine="line.product.ref" :thumb="line.thumbnail" :typeFile="'RECTO'"/>
      <Preparation @prepadone="preparationDone" :blockTitle="$t('Edit')" :lineid="line.rowid" v-if="ofstatus == 'DRAFT' && line.done == 'true'" :productQtyLine="line.qty" :productRefLine="line.product.ref" :thumb="line.thumbnail"  :typeFile="'RECTO'"/>

      <Preparation @prepadone="preparationDone" :blockTitle="$t('PrepareVerso')" :lineid="line.rowid" v-if="ofstatus == 'DRAFT' && !line.white_thumbnail" :productQtyLine="line.qty" :productRefLine="line.product.ref"  :thumb="line.white_thumbnail" :typeFile="'VERSO'"/>
      <Preparation @prepadone="preparationDone" :blockTitle="$t('EditVerso')" :lineid="line.rowid" v-if="ofstatus == 'DRAFT' && line.done == 'true' && line.white_thumbnail" :productQtyLine="line.qty" :productRefLine="line.product.ref"  :typeFile="'VERSO'" :thumb="line.white_thumbnail"/>


      <Impression @impressiondone="handleprint" @impressionend="handleprintend"  :line="line" v-if="(ofstatus == 'OPEN' || ofstatus == 'VALID') && line.date_start_line && !line.date_end_line && line.printablefile != '' && line.printablefile != 'https://bonbonprint.candyprint.eu/'" />
      <StartImpression @impressiondone="handleprint" :line="line" v-if="(ofstatus == 'OPEN' || ofstatus == 'VALID') && !line.date_start_line" :orderRef="orderRef"/>
      <!-- <EndImpression @impressionend="handleprintend" :line="line" v-if="(ofstatus == 'OPEN' || ofstatus == 'VALID') && line.date_start_line && !line.date_end_line && line.printablefile != ''" /> -->
      <span v-if="(ofstatus != 'DRAFT') && line.date_start_line && line.date_end_line"><br/>{{$t("ProductionTime:")}} {{tempsProdctionCalc  | prettyPrintSecond }} </span>
    </td>
  </tr>
</template>

<script>
import moment from "moment"
import Preparation from "@/components/buttons/preparation.vue"

import Impression from "@/components/buttons/impression.vue"
import StartImpression from "@/components/buttons/startimpression.vue"
//import EndImpression from "@/components/buttons/endimpression.vue"
export default {
  name : "OfLine",
  components: {
    Preparation,
    Impression,
    StartImpression,

  //  EndImpression
  },
  props : {
    line : Object,
    ofstatus : String,
    orderRef : String,
 },
 methods: {
   preparationDone : function(r){
     var dack = this.line;
     this.line = r.data
     this.line.orderline = dack.orderline
     this.$emit("updated",this.line)
     this.$forceUpdate()

   },
   handleprint : function(r){
     var dack = this.line
     this.line = r.data
     this.line.orderline = dack.orderline
     this.$forceUpdate()
  },
  handleprintend : function(r){
    var dack = this.line
    this.line = r.data
    this.line.orderline = dack.orderline
    this.$forceUpdate()
    this.$emit("anItemIsEnded")
 }
},
filters: {
  prettyPrintSecond: function (duration) {
    return (duration) ? moment({}).startOf('day').seconds(duration).format("HH[H] mm[min.] ss[sec.]") : "N.C.";
  }
},
computed: {
  tempsProdctionCalc : function(){
    var tempsProduction = this.line.date_end_line - this.line.date_start_line;
    return tempsProduction
    }
  }
}
</script>
