<template>
  <select v-model="locale" @change="switchLocale()" style="padding:10px;background:#FFF;" >
    <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale">
      {{ locale }}
    </option>
  </select>
</template>

<script>
export default {
  name: "LocaleSwitcher",
  data() {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
      locale :this.$i18n.locale
    };
  },
  methods: { // <---------------------------
    switchLocale() {
      if (this.$i18n.locale !== this.locale) {
        this.$i18n.locale = this.locale;
        this.$store.commit('setAppLanguage', this.locale)
      }
    }
  },
};
</script>
