<template>
  <div class="">

    <TopNav />
  <SideNav />
  <div id="home">
    <div id="page" class="container">
      <div v-if="loading" id="loader"><div class="loader"></div></div>
      <div class="hello">
        <h1>{{ $t('SalesAdministration') }}</h1>
        <div class="row">
          <div class="col-lg-4 col-12">
            <div class="card">
              <div class="card-body row">
                <div class="col-3 text-center">
                  <i class="fas fa-cart-arrow-down colorpurple fa-lg"></i>
                </div>
                <div class="col-9">
                  <h4>{{ $t('Orders') }}</h4>
                  {{orders.length}} {{ $t('WaitingBATOrders') }}
                </div>
              </div>
            </div>
          </div>
        </div>

      <div class="card mb-4" id="bloctaborder">
        <div class="card-body">
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="orders"
            item-key="id"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :search="search"
            :footer-props="footerProps"
            :item-class="itemRowBackground"
          >
          <template v-slot:top>
           <v-text-field
              style="width:45%;margin-left:2.5%;margin-right:2.5%;display:inline-block;"
             v-model="search"
             label="Recherche "
           ></v-text-field>
           <v-select
              style="width:45%;margin-left:2.5%;margin-right:2.5%;display:inline-block;"
              :items="advstatusList"
             :label="$t('Status')"
             item-text='label'
             item-value='_id'
             v-model="advstatus"
             ></v-select>
         </template>
          <template v-slot:item.ref="{ item }">
            <a :href="'/order/'+ item.id">{{item.ref}}</a>
          </template>

          <template v-slot:item.date_commande="{ item }">{{ item.date_commande | moment }}</template>
          <template v-slot:item.date_livraison="{ item }">{{ item.date_livraison | moment }}</template>
          <template v-slot:item.date_bat="{ item }">
            <span v-if="item.array_options.options_envoibat">{{ item.array_options.options_envoibat | momentText}}</span>
            <span v-else>{{ $t('NotSend') }}</span>
          </template>
          <template v-slot:item.date_validbat="{ item }">
              <span v-if="!item.array_options.options_envoibat">{{ $t('NotSend') }}<span v-if="!item.array_options.options_envoibat"><strong> {{ $t('since') }} {{ item.date_commande | diffWithToday2 }} {{ $t('days') }}</strong></span></span>
              <span v-if="item.array_options.options_envoibat && !item.array_options.options_validclientbat">{{ $t('WaitingValidation') }} <strong>{{ $t('since') }} {{ item.array_options.options_envoibat | diffWithToday}} {{ $t('days') }}</strong></span>
              <span v-if="item.array_options.options_validclientbat">{{ $t('ValidatedBAT') }} {{item.array_options.options_validclientbat | momentText}}</span>

          </template>
          <template v-slot:item.statut="{ item }">
            <span v-if="item.statut == 1" class="badge badge-secondary">
              <span v-if="!item.array_options.options_validclientbat">{{ $t('WaitingBAT') }} <span v-if="item.array_options.options_envoibat">{{ $t('ClientSide') }}<strong> {{ $t("since") }} {{ item.array_options.options_envoibat | diffWithToday}} {{ $t('days') }}</strong></span>
              <span v-if="!item.array_options.options_envoibat">{{ $t('OurSide') }} <strong> {{ $t('since') }} {{ item.date_commande | diffWithToday2}} {{ $t('days') }}</strong></span>
              </span>
              <span v-if="item.array_options.options_validclientbat">{{ $t('ValidatedBAT') }} {{item.array_options.options_validclientbat | momentText}}</span>
            </span>
          </template>

          <template v-slot:item.id="{ item }">
            <a :href="'/order/'+ item.id"><i class="far fa-eye"></i></a>
          </template>

        </v-data-table>
        </div>
      </div>
  </div>


    </div>
  </div>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import moment from 'moment'
import SideNav from "@/components/navbar.vue";
import TopNav from "@/components/topnav.vue";

export default {
  name: 'Home',
  components: {SideNav, TopNav},
  data(){
    return {
      loading: false,
      selected: [],
      orders : [],
      originalOrderList: [],
      ofdone: 10,
      oftotal: 70,
      advstatus: '',
      advstatusList:[
        {
        _id : '',
        label : "Tous"
      },
      {
        _id : 'NOTSEND',
        label : this.$i18n.t("BATNOTSEND"),
          },
          {
          _id : 'BATSEND',
          label : this.$i18n.t("BATSEND"),
        },
        {
        _id : 'BATVALID',
        label : this.$i18n.t("BATVALID"),
      },
      ],
      headers: [
        {
          text: 'N°',
          value: 'ref',
          sortable: true,
          align: 'left',
        },
        {
          text: this.$i18n.t("OrderCustomerRef"),
          value: 'ref_client',
          sortable: true,
          align: 'left',
        },
        {
          text: this.$i18n.t("OrderDate"),
          value: 'date_commande',
          sortable: true,
          align: 'left',
        },
        {
          text: this.$i18n.t("DeliveryDate"),
          value: 'date_livraison',
          sortable: true,
          align: 'left',
        },
        {
          text: this.$i18n.t("BATDate"),
          value: 'date_bat',
          sortable: true,
          align: 'left',
        },
        {
          text:this.$i18n.t("BATValidationDate"),
          value: 'date_validbat',
          sortable: true,
          align: 'left',
        },
        {
          text:this.$i18n.t("Customer"),
          value: 'client.nom',
          sortable: true,
          align: 'left',
        },
        {
          text: this.$i18n.t("Status"),
          value: 'statut',
          sortable: true,
          align: 'left',
        },
        {
          text: '#',
          value: 'id',
          sortable: true,
          width: '10%',
          align: 'left',
        },
      ],
      sortBy: 'date_commande',
      sortDesc: true,
      search: '',
      footerProps: {
        'items-per-page-options': [25, 50, 100, 500],
      },
    }
  },
  mounted () {
    this.loading = true
    axios
      .get(
        "https://crm.bonbonprint.fr/api/index.php/orders/ordersListWithOfs?sortfield=t.date_creation&sortorder=ASC&limit=200&sqlfilters=(t.fk_statut:=:'1')",
        {
          headers: {
            'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9'
            //the token is a variable which holds the token
          }
        }
      )
      .then(response => {
        console.log(response);
        this.orders = response.data.olist
        this.orders.forEach((o) => {
            o.ofdone = o.of.TAssetOFLine.filter((obj) => obj.done == "true").length;
        });
        this.originalOrderList = this.orders
        this.loading = false
      })
  },
  filters: {
    moment: function (date) {
      return (date) ? moment(date*1000).format('DD/MM/YYYY') : "N.C.";
    },
    momentText: function (date) {
      return (date) ? moment(date).format('DD/MM/YYYY') : "N.C.";
    },
    diffWithToday: function(date){
      var given = moment(date, "YYYY-MM-DD");
      var current = moment().startOf('day');
      console.log( moment.duration(given.diff(current)).asDays());
      //Difference in number of days
      return moment.duration(given.diff(current)).asDays();
  //    return  moment().diff(moment(date,"DD/MM/YYYY"),'days')
    },
    diffWithToday2: function(date){
      var given = moment(date * 1000);
      return moment().startOf('day').diff(given, "days");
      // var current = moment().startOf('day');
      // console.log( given);
      // //Difference in number of days
      // return moment.duration(given.diff(current)).asDays();
  //    return  moment().diff(moment(date,"DD/MM/YYYY"),'days')
    }


  },
  methods: {
    itemRowBackground: function (item) {
      if(item.array_options.options_validclientbat) return "bgGreen";
      if(item.array_options.options_envoibat && !item.array_options.options_validclientbat)return 'bgOrange';
      if(!item.array_options.options_envoibat)return 'bgRed';
      else return 'bgGrey'
  }
},
watch : {
    advstatus: function () {
      let r = this.originalOrderList;
      switch (this.advstatus) {
        case "NOTSEND":
          r = this.originalOrderList.filter(order => !order.array_options.options_envoibat);
          this.orders = r;
          break;
        case "BATSEND":
          r = this.originalOrderList.filter(order => (order.array_options.options_envoibat && !order.array_options.options_validclientbat));
          this.orders = r;
          break;
        case "BATVALID":
          r = this.originalOrderList.filter(order => order.array_options.options_validclientbat);
          this.orders = r;
          break;
        default:
        this.orders = r;
      }

      console.log(this.advstatus);
    },
    deep: true
   },


}
</script>
<style media="screen">
  .bgGreen{
    background: #98e58e;
  }
  .bgRed{
    background: #ea8f8f;
  }
  .bgOrange{
    background: #f7db6a;
  }

  #loader{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.8);
    z-index: 500000;
  }
  .loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 250px;
  height: 250px;
  transform: translate(-50%,-50%);
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
    animation: spin 2s linear infinite;
  }


  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  </style>
