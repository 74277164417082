<template lang="html">
  <button type="button" class="btn btn-success" name="button" @click="validateOrderFab">
    <i class="far fa-share-square"></i>
    {{$t("SendToProduction")}}</button>
</template>

<script>
import axios from 'axios';
export default {
  name: "SendToProduction",
  props : [
    "ofid"
  ],
  methods: {
    validateOrderFab : function(){
      if(confirm("Envoyer en production ?")){
        console.log("Confirmed");
        axios.post(
          "https://crm.bonbonprint.fr/api/index.php/of/validerof",
          {ofid:parseInt(this.ofid)},
          {
            headers: {
              'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
            }
          }
        )
        .then(response => {
          console.log(response);
          this.$emit('validated');
        });
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
