import Vue from 'vue'
import VueRouter from 'vue-router'
import BAT from '../views/BAT.vue'
import ADV from '../views/ADV.vue'
import Orders from '../views/Orders.vue'
import ProductionsList from '../views/ProductionsList.vue'
import AuDepart from '../views/AuDepart.vue'
import Incident from '../views/Incident.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import OrderAddForm from '../views/OrderAddForm.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'BAT',
    component: BAT
  },
  {
    path: '/adv',
    name: 'ADV',
    component: ADV
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders
  },
  {
    path: '/order/:id',
    name: 'Order',
    component: Orders,
    props: true
  },
  {
    path: '/productions/:printer?',
    name: 'Production',
    component: ProductionsList
  },
  {
    path: '/audepart',
    name: 'Au départ',
    component: AuDepart
  },
  {
    path: '/incident',
    name: 'Suivi d\'incident',
    component: Incident
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/addOrder',
    name: 'AddOrder',
    component: OrderAddForm
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
/* eslint-disable */
router.beforeEach(async (to,from,next) => {
  var isAuthenticated = $cookies.get("geadash_username");
  if (!isAuthenticated && to.name !== 'Login') {
    return next({ name: 'Login' })
  }
  else next();
})

export default router
