<template><div>
  <TopNav/>
    <SideNav />
      <OrderCard :orderid="this.$route.params.id" />
    </div>
</template>

<script>
// @ is an alias to /src
import OrderCard from '@/components/OrderCard.vue'
import SideNav from "@/components/navbar.vue";
import TopNav from "@/components/topnav.vue";
export default {
  name: 'OrdersView',
  components: {
    OrderCard,
    SideNav,
    TopNav
  }
}
</script>
