<template lang="html">
  <div class="btnBlock">
    <button type="button" name="button" class="btn btn-warning" @click="printme">
      <i class="fas fa-print"></i>
      {{$t("PrintOfPDF")}}
    </button>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "PrintOfPdf",
  props : [
    "ofid"
  ],
  methods: {
    printme : function(){
      axios
        .get(
          "https://crm.bonbonprint.fr/api/index.php/of/printTheOdt?ofid="+this.ofid+"&direct=true",
          {
            headers: {
              'DOLAPIKEY': 'bb30b37967cc61e97da43f53467e94ca1c0fabf9' //the token is a variable which holds the token
            }
          }
        )
        .then(response => {
          // axios.post(
          //     "http://bonbonprint.candyprint.eu/ws/odttopdfconverter",
          //     {fileUrl:"http://crm.bonbonprint.fr"+response.data},
          //   )
          //   .then(r => {
          //     var pdfcontent = r.data.fileContent;
          //     var pdfname = "OF-"+this.ofid+".pdf";
          //     const linkSource = "data:application/pdf;base64,"+pdfcontent;
          //     const downloadLink = document.createElement("a");
          //     const fileName = pdfname;
          //     downloadLink.href = linkSource;
          //     downloadLink.download = fileName;
          //     downloadLink.click();
          //   });

          var pdfname = "OF-"+this.ofid+".odt";
          const linkSource = "https://crm.bonbonprint.fr"+response.data;
          const downloadLink = document.createElement("a");
          const fileName = pdfname;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();

        });
    },
  }
}
</script>

<style lang="css" scoped>
</style>
